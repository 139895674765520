:root {
    --highlight: #59a1ff;
    --text: #ffffff;
}

@font-face {
    font-family: Geosans-Light;
    src: url("./assets/Geosans-Light.woff") format("woff");
}

body, html {
    font-family: Geosans-Light, serif;
    position:relative;
    #background-color: #ffffff;
    height: 100%;
}

.App {
    #background-color: #ffffff;
    height: 100%;
    min-height: 100%;
}

@media screen and (min-width: 768px) {
    .App-header {
        margin: 0 auto 0 150px;
        padding-top: 170px;
        color: #808080;
        max-width: 600px;
    }
}

@media screen and (max-width: 768px) {
    .App-header {
        margin: 0 auto 0 70px;
        padding-top: 100px;
        color: #ffffff;
        max-width: 600px;
    }
}

.App-title {
    text-align: left;
    font-size: 30px;
    color: var(--highlight);
}

.App-subtitle {
    text-align: left;
    font-size: 20px;
    color: #ffffff;
    padding-bottom: 10px;
    text-shadow: 0px 1px 2px rgb(0 0 0 / 80%);
}

.Header-Main {
    margin-left: 70px;
    margin-top: auto;
    display: table-cell;
    height: 100%;
}

.Header-section {
    font-size: 15px;
    padding-bottom: 5px;

    color: white;

    text-decoration-skip-ink: auto;
    text-align: center;
    text-decoration: none;
    height: 100%;
    text-shadow: 0px 1px 2px rgb(0 0 0 / 80%);
}

.Header-section-selected {
    font-size: 30px;
    border: 2px solid #808080;
    border-radius: 10px;
    padding: 5px;
    background-color: rgb(166, 166, 166);

    text-decoration-skip-ink: auto;
    text-align: center;
}

.Header-sections {
    margin-bottom: 20px;
    width: 100%;
}

.Header-section {
    display: block;
    width: 100%;
    text-align: right;
    color: #ffffff;
}

.Header-logos-container {
    text-align: left;
    padding-bottom: 10px;
}

.Header-logos {
    text-decoration: none;
}

.Header-logos-img {
    width: 25px;
    margin-right: 4px;
    color: #808080;
    filter: drop-shadow( 1px 1px 1px #55555555);
}

@media screen and (min-width: 768px) {
    .Navigation-Container {
        display: inline;
        float: left;
        width: 130px;
        height: 300px;
    }
}

@media screen and (max-width: 768px) {
    .Navigation-Container {
        display: inline;
        float: left;
        width: 50px;
        height: 300px;
    }
}

.App-intro {
    font-size: large;
}

@media screen and (min-width: 768px) {
    .Section {
        margin: 0 auto;
        padding-left: 150px;
        padding-right: 150px;
    }
}

@media screen and (max-width: 768px) {
    .Section {
        color: #808080;
        margin: 0 auto 0 70px;
        padding-right: 30px;
    }
}

.About-img {
    height: 200px;
    text-align: center;
    margin: 20px auto;
}

.About-txt {
    text-align: left;
    max-width: 400px;
    color: white;
    text-shadow: 0px 1px 2px rgb(0 0 0 / 80%);
}

.GamesHeader {
    font-size: 17px;
    text-align: left;
    color: var(--highlight);
    font-kerning: none;
    font-weight: bold;
}

.Grid {
    display: grid;
    flex-wrap: wrap;
    justify-self: left;
    justify-content: left;
    max-width: 600px;
    padding-bottom: 15px;
    padding-left: 5px;
}

.Grid-Item {
    color: #ffffff;
    align-self: start;
    justify-self: left;
    margin-top: 5px;
    text-decoration: none;
    text-align: left;
    text-shadow: 0px 1px 2px rgb(0 0 0 / 80%);
}

.Grid-Item > img {
    margin-top: 5px;
    width: 180px;
    min-height: 180px;
}


.Game {

}

.Game-Description {
    text-align: left;
    white-space: pre-line;
}

.Game-Icon {
    width: 20px;
    height: 20px;
    filter: invert(100%);
}

.Game-Links > * {
    margin: 5px;
}

.modal {
    width:80%;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    background: rgb(255, 255, 255);
    top: 25px;
    border: 2px solid #444;
    min-height: 300px;
    max-width: 500px;
    padding:10px;
}

.modal-backdrop {
    backgroundColor: rgba(0,0,0,0.3);
    left: 50%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
}

.modal-close-top-container {
    height:15px;
}

.modal-close-top {
    border: 1px solid black;
    width:25px;
    height:25px;
    line-height: 23px;
    align-content: center;
    text-align: center;
    float:right;
    position:absolute;
    right: 15px;
}

.modal-close-bottom-container {
    height:30px;
}

.modal-close-bottom {
    border: 1px solid black;
    text-align: center;
    width:100%;
    margin-top: 10px;
    height:20px;
}

.modal-close-bottom:active, .modal-close-top:active {
    background: grey;
}

.modal-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
}

.Game-Modal {
    flex-grow: 2;
}

.Game-Modal > * {
    text-align: center;
}

.Game {
    width: 50%;
    color: #ffffff;
    margin: auto;
    min-height: 300px;
}

.Game > * {
    text-align: center;
    width: 100%;
    margin: auto;
}

.Game-Carousel {
    width: 100%;
    margin: auto;
}

.CompanyRow {
    text-align: start;
    display:inline;
    padding-left: 5px;
}

.WorkImage {
    width: 50px;
    height: 50px;
    padding: 5px 2px;
}

.footerContainer {
    position: relative;
}
.footer {
    position: absolute;
    bottom: 0;
    color:white;
    text-decoration-color: #59A1FFFF;
    filter: drop-shadow( 3px 3px 3px #000);
}

.footer a {
    color:#59A1FFFF;
    font-weight: bold;
}